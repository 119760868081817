<template>
  <div id="app-master">
    <div id="page" :style="currentPage && signupImage" class="ml-0 bgImage"> 
      <div id="user-pages">
        <div class="container">
          <main>
            <router-view />
          </main>
        </div>
      </div>
      <MasterFooter />
    </div>
  </div>
</template>

<script>
import MasterFooter from "@/components/MasterFooter.vue";

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "User",
  components: {
    MasterFooter,
  },
  data() {
    return {
      signupImage: null,
    };
  },
  // todo the entire code below needs to be refactored during migration
  updated(){
    let user = null;
    if (this.$route.path.includes('student-welcome') 
      || this.$route.path.includes('student-rising-star') || this.$route.path.includes('user/welcome') || this.$route.path.includes('student-signup') || this.$route.path.includes('student-talent')) {
        user = 'Student'
      }else{
        user = 'Professional'
      }
    if(!this.$store.getters.studentContent || !this.$store.getters.professionalContent) {
      this.$store.dispatch('getStudentContent')
      this.$store.dispatch('getProfessionalContent')
    }
  },
  watch: {
    signupData: function (val) {
      this.signupImage = {
        backgroundImage: `url(${this.signupData.image_path})`,
        // backgroundImage: `url(${process.env.VUE_APP_BASE_URL}${this.$route.path.includes('professional-signup/onboarding') ? this.signupData.image_path})`,
      };
    },
  },
  computed: {
    currentPage() {
      return this.$route.path.includes("signup") || this.$route.path.includes('professional-signup/onboarding') || this.$route.path.includes('student-welcome') 
      || this.$route.path.includes('student-rising-star') || this.$route.path.includes('user/welcome') || this.$route.path.includes('student-talent')
      ? true : false;
    },
    signupData: function () {
      var vm = this;
      return vm.$store.getters.signupData;
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/style.scss"></style>

<style scoped>
  .bgImage{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  #app #app-master #page{
    padding: 0!important;
  }
  /* @media(max-width: 767px){
    #app #app-master #page{
    background-image: none!important;
  }
  } */
</style>
